import React, { useContext, useEffect, useState } from 'react';
import contextData from '../context/MainContext';
import { useMediaQuery } from '@chakra-ui/react';

import { Link, useLocation } from 'react-router-dom';
import { CategoryLoading } from '../Component/Loaders/SkeletonLoader';

import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';

const Category = (props) => {

    const data = useContext(contextData);
    const [isNotSmallerScreen] = useMediaQuery("(min-width:1024px)");
    const location = useLocation();
    const [categories, Setcategories] = useState([]);
    const [loca, setLcoa] = useState()

    useEffect(() => {
        Setcategories(data.categories);

    }, [data.categories]);





    return (
        <>
            <div class="pt-4">
                {location.pathname !== "/category" &&
                    <div class="d-flex align-items-center mb-2">
                        <h5 class="m-0">What do you looking for??</h5>
                    </div>
                }
                <div class="row m-1">
                    {data.isLoading ? (
                        <>
                            <CategoryLoading />
                            <CategoryLoading />
                            <CategoryLoading />
                            <CategoryLoading />
                        </>
                    ) : categories.length ? (
                        < >
                            {categories.map((item, i) => {
                                return (
                                    <div class="col-6 col-md-3 mb-3 p-1" key={i}>
     <Card className=' h-100 rounded overflow-hidden position-relative shadow-sm'>
      <Card.Img variant="top" src="https://upload.wikimedia.org/wikipedia/commons/3/33/Vanamo_Logo.png" />
      <Card.Body className='text-center'>
        <Card.Title className='text-center'>{item.name}</Card.Title>
       
      </Card.Body>
    </Card>

                                      
                                    </div>
                                )
                            })}
                        </>
                    ) : null}
                
                </div>
            </div>
        </>
    )

}

export default Category;


