import React, { useContext, useEffect } from 'react';
import NavBar from '../Component/NavBar'
import Category from '../Component/Category'
const HomePage = () => {

  return (
    <>
    <NavBar/>
    <Category/>

    </>
  );
}

export default (HomePage);
