import React, { useReducer, useState, useEffect } from "react";
import Context from "./MainContext";
import { reducer } from '../reducer/reducer';
// import URL from "../Extra/URL";
// import { useNavigate } from 'react-router';


const  URL = 'https://testingandroidapp.hqleadsystem.com';
const ContextProvider = props => {

    const MainData = {
     
        categories: [],
       
       
    };

    const fetchData = () => {
        fetch(URL + "/APP-API/App/reloadData", {
            method: 'POST',
            header: {
                'Accept': 'application/json',
                'Content-type': 'application/json'
            },
            body: JSON.stringify({

            })
        }).then((response) => response.json())
            .then((responseJson) => {

                console.log("backend res",responseJson)
                functionality.fetchAllData(responseJson);
            })
            .catch((error) => {
                //  console.error(error);
            });
    };

    const functionality = {
        fetchAllData: payload => dispatch({ type: "FETCH_ALL_DATA", payload }),
        reloadData: fetchData,

    };




    useEffect(() => {
        fetchData()
    }, [])

    const [MainDataExport, dispatch] = useReducer(reducer, MainData);

    return (
        <Context.Provider value={{
            ...MainDataExport,
            ...functionality
        }}>
            {props.children}
        </Context.Provider>
    )
}

export default ContextProvider;