import React, { Component, useEffect } from 'react';
import ContextProvider from './context/contextProvider';
import { BrowserRouter, Routes, Route } from 'react-router-dom';

import HomePage from './Page/HomePage';



class App extends Component {




  render() {
   
      return (
        <ContextProvider>
          {/* <ChakraProvider> */}
          <BrowserRouter>
            <Routes>
              <Route path="/" element={<HomePage />} />
  

              {/* USER ACCOUNT END */}
            </Routes>
          </BrowserRouter>
          {/* </ChakraProvider> */}
        </ContextProvider>
      );
   
  }
}

export default App;